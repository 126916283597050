import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// CSS
import './Months.scss';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';

// Helper
import {checkCookie} from '../../Helper/Cookies';

// Properties
const properties = require('../../properties.json');

class Months extends Component {
    state = {
        namedMonths: [
            "يناير",
            "فبراير",
            "مارس",
            "إبريل",
            "مايو",
            "يونيو",
            "يوليو",
            "أغسطس",
            "سبتمبر",
            "أكتوبر",
            "نوفمبر",
            "ديسمبر"
        ],

        // Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).studentData : {},

        // Get Months
        months: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).studentData.monthList : []
    }

    componentDidMount() {
        document.title = properties.site_name + " - كل الشهور";

        // Redirect To Sign In Page If The User Is Already Logged Out & Remove The Data If It Exists
        console.log(checkCookie('email'));
        if (!checkCookie('email')) {
            window.localStorage.removeItem('currentUser');
            window.location.href = "/sign-in";
        }
    }

    render() {
        let student = (this.state.months.length) ? this.state.currentUser.fullName : "";

        let allMonths = this.state.months.map(month => 
            <div className="col-md-4" key={month}>
                <Link to={`/months/${month}`}>
                    <div className="single-month">
                        <div className="wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" width="61.951" height="65.166" viewBox="0 0 61.951 65.166">
                                <path id="Icon_map-school" dataname="Icon map-school" d="M39.149,10.147V54.59H6.827V10.147H39.149M44.536,4.76H1.44V61.324h43.1V4.76ZM10.867,15.534H35.109v5.387H10.867Zm0,9.427H35.109v5.387H10.867Zm0,9.427H35.109v5.387H10.867Zm0,9.427H35.109V49.2H10.867Zm41.75-28.282H63.391V53.244H52.617ZM58.012.72a5.367,5.367,0,0,0-5.4,5.3v5.472H63.391V6.022A5.361,5.361,0,0,0,58.012.72ZM52.533,57.284l5.442,8.6,5.415-8.6Z" transform="translate(-1.44 -0.72)" fill="#198baf"/>
                            </svg>
                            <h4>شهر {this.state.namedMonths[month-1]}</h4>
                            <span>{month}/{new Date().getFullYear()}</span>
                        </div>
                    </div>
                </Link>
            </div>
        );

        return (
            <>
                <Header />
                <PageHeader pageName={"أهلا، " + student} />
                <div className="student-main-page">
                    <div className="container">
                        <div className="section-title">
                            <h3>الشهور المدفوعة</h3>
                            <div className="hr"></div>
                        </div>

                        {/* <div className="no-months">
                           <p>لم يتم دفع أي شهور حتى الأن</p>
                        </div> */}
                        
                        <div className="months">
                            <div className="row">
                                {allMonths}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Months;