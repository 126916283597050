import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// CSS
import './Footer.scss';

// Assets
import LogoWhite from '../../assets/img/logo-footer.png';

// Properties
const properties = require('../../properties.json');

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="info">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                                <div className="contact">
                                   <a href={`mailto ${properties.teacher.email}`}>{properties.teacher.email}</a>
                                   <a href={`tel: ${properties.teacher.phone}`}>{properties.teacher.phone}</a>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <Link to="/">
                                    <img src={LogoWhite} alt={properties.teacher.name} />
                                </Link>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-auto">
                                <div className="social-media">
                                    {/* <a href="/youtube" target="_blank">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a href="/twitter" target="_blank">
                                        <i className="fab fa-twitter"></i>
                                    </a> */}
                                    <a href={properties.teacher.social['facebook-f']} rel="noreferrer" target="_blank">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copywrights">
                    <div className="container">
                        <span>جميع حقوق المحتويات محفوظة للأستاذ {properties.teacher.name} 2021 - 2022</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;