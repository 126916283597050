import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Home from './pages/Home/Home.js';
import SignUp from './pages/Sign-Up/SignUp.js';
import SignIn from './pages/Sign-In/SignIn.js';
import Months from './pages/Months/Months.js';
import Videos from './pages/Videos/Videos.js';

class Connector extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/months" exact>
            <Months />
          </Route>
          <Route path="/months/:id" component={Videos} />
        </Switch>
      </Router>
    );
  }
}

export default Connector;
