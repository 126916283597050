import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// CSS
import './Videos.scss';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';
import { Player } from 'video-react';

// Properties
const properties = require('../../properties.json');

class Videos extends Component {
    state = {
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {},

        // Get Month Parameter From URL
        monthID: (this.props.match.params.id) ? parseInt(this.props.match.params.id) : 0,

        // Attachment Tab
        openAttchment: false,

        // First Lesson
        currentLessonID: 1,

        // Months With Names
        namedMonths: [
            "يناير",
            "فبراير",
            "مارس",
            "إبريل",
            "مايو",
            "يونيو",
            "يوليو",
            "أغسطس",
            "سبتمبر",
            "أكتوبر",
            "نوفمبر",
            "ديسمبر"
        ],

        // All Lessons
        lessons: []
    }

    componentDidMount() {
        document.title = properties.site_name + " - شهر نوفمبر";
        
        // Get Lessons From API
        fetch(properties.api_path + "/lesson/lessonList", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + this.state.currentUser.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                branchId: this.state.branchID,
                companyId: this.state.companyID,
                monthId: this.state.monthID,
                schoolId: this.state.currentUser.studentData.schoolStagesId
            })
        })
        .then(res => {return res.json()})
        .then(allLessons => {
            console.log(allLessons);
            this.setState({currentLessonID: allLessons[0].id, lessons: allLessons});
        })
    }

    render() {
        let month = `شهر ${this.state.namedMonths[this.state.monthID - 1]}`;

        let getLessons = this.state.lessons.map((singleLesson, i) => 
            <div key={singleLesson.id} className={`video-item ${(this.state.currentLessonID === singleLesson.id) ? "active": ""}`} onClick={() => this.setState({currentLessonID: singleLesson.id})}>
                <h3><span className="index">{i + 1}.</span> {singleLesson.lessonName}</h3>
                <i className={`${(this.state.currentLessonID === singleLesson.id) ? "fas fa-check-circle": "far fa-circle"}`}></i>
            </div>
        );

        let currentLesson = this.state.lessons[this.state.lessons.findIndex(id => id.id === this.state.currentLessonID)];
        console.log(currentLesson);

        return (
            <>
                <Header />
                <PageHeader pageName={month} />

                <div className="month-videos">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="videos-list">
                                    {getLessons}
                                </div>
                            </div>

                            {(currentLesson) ? (
                                <div className="col-md-8">
                                    <div className="video-details">
                                        <Player
                                            playsInline
                                            src={properties.videos_path + currentLesson.videoLink}
                                        />
                                        <h2>{currentLesson.lessonName}</h2>
                                        <div className="description">{currentLesson.lessonDescription}</div>
                                        <div className="attachments" onClick={() => this.setState({openAttchment: !this.state.openAttchment})}>
                                            <div className="title">
                                                <span>ملحقات الدرس <i className="fas fa-paperclip"></i></span>
                                                <i className="fas fa-caret-down"></i>
                                            </div>

                                            <div className={"files " + (this.state.openAttchment ? "active": "")}>
                                                <div className="single-file">
                                                    <a href="/">
                                                        <span className="name"><i className="fas fa-file-alt"></i> مذكرة الشرح</span>
                                                        <span className="size">5 MB</span>
                                                    </a>
                                                </div>
                                                <div className="single-file">
                                                    <a href="/">
                                                        <span className="name"><i className="fas fa-file-alt"></i> إمتحان</span>
                                                        <span className="size">10 MB</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                            ): ""}
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Videos;