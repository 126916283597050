import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Plugin
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// CSS
import './Home.scss';


// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// Properties
const properties = require('../../properties.json');

class Home extends Component {
    componentDidMount() {
        document.title = properties.site_name;
    }

    render() {
        const options = {
            showThumbs: false,
            showStatus: false,
            autoPlay: true,
            stopOnHover: false,
            infiniteLoop: true,
            interval: 7000
        };

        let teacherName = properties.teacher.name;
        let teacherImage = require(`../../assets/img/${properties.teacher.img}`).default;

        return(
            <>
                <Header />

                <div className="home-slider">
                    <Carousel {...options}>
                        <div className="slide" style={{backgroundImage: `url(${require(`../../assets/img/1.jpeg`).default})`}}>
                            <div className="overlay">
                                <div className="container">
                                    <h3>ادرس {properties.field} اونلاين مع الاستاذ {teacherName}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="slide" style={{backgroundImage: `url(${require(`../../assets/img/2.jpeg`).default})`}}>
                            <div className="overlay">
                                <div className="container">
                                    <h3>ادرس {properties.field} اونلاين مع الاستاذ {teacherName}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="slide" style={{backgroundImage: `url(${require(`../../assets/img/3.jpeg`).default})`}}>
                            <div className="overlay">
                                <div className="container">
                                    <h3>ادرس {properties.teacher.field} اونلاين مع الاستاذ {teacherName}</h3>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className="user-buttons">
                    <div className="row mx-0 no-gutters">
                        <div className="col-md-6">
                            <Link className="u-btn sign-up" to="sign-up"><i className="fas fa-user-plus"></i> أنشئ حساب جديد</Link>
                        </div>
                        <div className="col-md-6">
                            <Link className="u-btn sign-in" to="sign-in"><i className="fas fa-sign-in-alt"></i> سجل دخولك الأن</Link>
                        </div>
                    </div>
                </div>

                <div className="about-teacher">
                    <div className="container">
                        <div className="section-title">
                            <h3>عن الأستاذ {teacherName}</h3>
                            <div className="hr"></div>
                        </div>

                        <div className="teacher-bio">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <p>{properties.teacher.description}</p>
                                </div>

                                <div className="col-md-4">
                                    <img src={teacherImage} alt={teacherName} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="free-videos">
                    <div className="container">
                        <div className="section-title">
                            <h3>الڤيديوهات المجانية</h3>
                            <div className="hr"></div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="video">
                                    <Link to="/videos">
                                        <div className="thumb">
                                            <img src={require(`../../assets/img/video-1.png`).default} alt="آلية عمل العام الدراسي الجديد"/>
                                            <div className="hover-layer">
                                                <i className="fas fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="video-title">
                                            <h4>آلية عمل العام الدراسي الجديد</h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="video">
                                    <Link to="/videos">
                                        <div className="thumb">
                                            <img src={require(`../../assets/img/video-2.png`).default} alt="آلية عمل العام الدراسي الجديد"/>
                                            <div className="hover-layer">
                                                <i className="fas fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="video-title">
                                            <h4>آلية عمل العام الدراسي الجديد</h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="video">
                                    <Link to="/videos">
                                        <div className="thumb">
                                            <img src={require(`../../assets/img/video-3.png`).default} alt="آلية عمل العام الدراسي الجديد"/>
                                            <div className="hover-layer">
                                                <i className="fas fa-play-circle"></i>
                                            </div>
                                        </div>
                                        <div className="video-title">
                                            <h4>الدعامة فى الانسان / العمود الفقري</h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Link to="/free-videos" className="all-videos">كل الڤيديوهات</Link>
                    </div>
                </div>
           
                <Footer />
            </>
        );
    }
}

export default Home;