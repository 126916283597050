import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';

// CSS
import './Header.scss';

// Helper
import {checkCookie} from '../../Helper/Cookies';

// Assets
import Logo from '../../assets/img/logo.png';

class Header extends Component {
    state = {
        // Get Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : "",

        // Open User Menu,
        menuOpened: false
    }

    componentDidMount() {
        // If The Cookies Is Removed, Remove The Data Stored In The Browser
        if (!checkCookie('email')) {
            window.localStorage.removeItem('currentUser');
        }
    }

    // Logout of The System
    logout = (e) => {
        e.preventDefault();

        document.cookie = "email =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        window.localStorage.removeItem('currentUser');
        
        setTimeout(() => {
            window.location.href = "/";
        }, 500);
    }

    render() {
        let userName = this.state.currentUser ? this.state.currentUser.studentData.fullName : "";

        return(
            <header className="main-header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                            <img src={Logo} alt="Educator" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <NavLink exact activeClassName="selected" className="nav-link" to="/">الرئيسية</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link" to="/free-videos">الڤيديوهات المجانية</NavLink>
                                </li>

                                {(this.state.currentUser) ? (
                                    // User Logged In
                                    <>
                                        <li className="nav-item">
                                            <NavLink activeClassName="selected" className="nav-link" to="/months">ڤيديوهات الشرح</NavLink>
                                        </li>
                                        <li className="nav-item dropdown user-menu">
                                            <a className="nav-link dropdown-toggle" onClick={(e) => {e.preventDefault(); this.setState({menuOpened: !this.state.menuOpened});}} href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {userName} <img src={require(`../../assets/img/pp.jpg`).default} alt={userName} />
                                            </a>
                                            <div className={`dropdown-menu ${(this.state.menuOpened) ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                                <a className="dropdown-item" href="/">حسابي <i className="far fa-id-card"></i></a>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" onClick={this.logout} href="/">تسجيل الخروج <i className="fas fa-sign-out-alt"></i></a>
                                            </div>
                                        </li>
                                    </>
                                ): (
                                    // User Logged Out
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link button sign-in" to="/sign-in">تسجيل الدخول</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link button sign-up" to="/sign-up">تسجيل جديد</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;