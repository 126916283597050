import React, {Component} from 'react';

// CSS
import './PageHeader.scss';

class PageHeader extends Component {
    render() {
        return (
            <div className="page-header">
                <div className="container">
                    <h2>{this.props.pageName}</h2>
                </div>
            </div>
        );
    }
}

export default PageHeader;